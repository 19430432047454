const Mapping = [
  {
    "name_short": "DECIDE-AI",
    "name_full": "Developmental and Exploratory Clinical Investigation of Decision-support Systems Driven by Artificial Intelligence",
    "year": "2021",
    "link_to_study": "https://www.nature.com/articles/s41591-021-01229-5",
    "focus_area": "Decision-support systems",
    "d": "M262.02,262.428c0,2.969-2.43,5.398-5.398,5.398H183.42c-2.97,0-5.4-2.43-5.4-5.398v-8.258c0-2.971,2.432-5.4,5.4-5.4h73.201c2.97,0,5.398,2.432,5.398,5.4V262.428z"
  },
  {
    "name_short": "ECLAIR",
    "name_full": "Evaluating Commercial AI Solutions in Radiology",
    "year": "2021",
    "link_to_study": "https://link.springer.com/article/10.1007/s00330-020-07684-x",
    "link_to_checklist": "https://link.springer.com/article/10.1007/s00330-020-07684-x/tables/1",
    "focus_area": "Radiology",
    "d": "M580.975,262.428c0,2.969-2.43,5.398-5.4,5.398h-73.201c-2.969,0-5.397-2.43-5.397-5.398v-8.258c0-2.971,2.431-5.4,5.397-5.4h73.201c2.973,0,5.4,2.432,5.4,5.4V262.428z"
  },  
  {
    "name_short": "CLAIM",
    "name_full": "CheckList for AI in Medical Imaging",
    "year": "2020",
    "link_to_study": "https://pubs.rsna.org/doi/full/10.1148/ryai.2020200029",
    "link_to_checklist": "https://pubs.rsna.org/cms/10.1148/ryai.2020200029/asset/images/large/ryai.2020200029.tbl1.jpeg",
    "focus_area": "Medical imaging",
    "d": "M105.108,204.333c0,2.969-2.43,5.398-5.398,5.398H26.507c-2.969,0-5.399-2.43-5.399-5.398v-8.258c0-2.971,2.433-5.4,5.399-5.4h73.201c2.971,0,5.398,2.43,5.398,5.4L105.108,204.333L105.108,204.333z"
  },
  {
    "name_short": "PRIME",
    "name_full": "Proposed Requirements for Cardiovascular Imaging-related Machine Learning Evaluation",
    "year": "2020",
    "link_to_study": "https://www.sciencedirect.com/science/article/abs/pii/S1936878X20306367",
    "link_to_checklist": "https://ars.els-cdn.com/content/image/1-s2.0-S1936878X20306367-fx1_lrg.jpg",
    "focus_area": "Cardiovascular imaging",
    "d": "M105.108,117.23c0,2.971-2.43,5.4-5.398,5.4H26.507c-2.969,0-5.399-2.43-5.399-5.4v-8.257c0-2.97,2.433-5.399,5.399-5.399h73.201c2.971,0,5.398,2.43,5.398,5.399L105.108,117.23L105.108,117.23z"
  },
  {
    "name_short": " SPIRIT-AI",
    "name_full": "Standard Protocol Items: Recommendations for Interventional Trials–Artificial Intelligence",
    "year": "2020",
    "link_to_study": "https://www.nature.com/articles/s41591-020-1037-7",
    "link_to_checklist": "https://www.nature.com/articles/s41591-020-1037-7/tables/2",
    "extension_of": "SPIRIT",
    "focus_area": "Clinical trial protocols",
    "d": "M420.24,233.391c0,2.973-2.43,5.4-5.398,5.4H341.64c-2.969,0-5.397-2.43-5.397-5.4v-8.256c0-2.971,2.431-5.4,5.397-5.4h73.201c2.971,0,5.398,2.43,5.398,5.4V233.391z"
  },
  {
    "name_short": "CONSORT-AI",
    "name_full": "Consolidated Standards of Reporting Trials–Artificial Intelligence",
    "year": "2020",
    "link_to_study": "https://www.nature.com/articles/s41591-020-1034-x",
    "link_to_checklist": "https://www.nature.com/articles/s41591-020-1034-x/tables/2",
    "extension_of": "CONSORT",
    "focus_area": "Clinical trial results",
    "d": "M420.24,262.428c0,2.969-2.43,5.398-5.398,5.398H341.64c-2.969,0-5.397-2.43-5.397-5.398v-8.258c0-2.971,2.431-5.4,5.397-5.4h73.201c2.971,0,5.398,2.432,5.398,5.4V262.428z"
  },  
  {
    "name_short": "STARD-AI",
    "name_full": "Standards for Reporting of Diagnostic Accuracy Studies–Artificial Intelligence",
    "year": "2020",
    "link_to_study": "https://www.nature.com/articles/s41591-020-0941-1",
    "extension_of": "STARD",
    "focus_area": "Diagnostic accuracy studies",
    "d": "M105.108,262.428c0,2.969-2.43,5.398-5.398,5.398H26.507c-2.969,0-5.399-2.43-5.399-5.398v-8.258c0-2.971,2.433-5.4,5.399-5.4h73.201c2.971,0,5.398,2.432,5.398,5.4L105.108,262.428L105.108,262.428z"
  },
  {
    "name_short": "MI-CLAIM",
    "name_full": "Minimum Information about Clinical Artificial Intelligence Modeling",
    "year": "2020",
    "link_to_study": "https://www.nature.com/articles/s41591-020-1041-y",
    "link_to_checklist": "https://www.nature.com/articles/s41591-020-1041-y/tables/1",
    "focus_area": "General modelling",
    "d": "M105.108,233.391c0,2.973-2.43,5.4-5.398,5.4H26.507c-2.969,0-5.399-2.43-5.399-5.4v-8.256c0-2.971,2.433-5.4,5.399-5.4h73.201c2.971,0,5.398,2.43,5.398,5.4L105.108,233.391L105.108,233.391z"
  },
  {
    "name_short": "TRIPOD-ML",
    "name_full": "Transparent Reporting of a Multivariable Prediction Model for Individual Prognosis or Diagnosis - Machine Learning",
    "year": "2019",
    "link_to_study": "https://www.thelancet.com/journals/lancet/article/PIIS0140-6736(19)30037-6/fulltext",
    "extension_of": "TRIPOD",
    "focus_area": "Prognostic or diagnostic studies",
    "d": "M105.108,146.264c0,2.971-2.43,5.4-5.398,5.4H26.507c-2.969,0-5.399-2.43-5.399-5.4v-8.257c0-2.97,2.433-5.4,5.399-5.4h73.201c2.971,0,5.398,2.431,5.398,5.4L105.108,146.264L105.108,146.264z"
  },  
  {
    "name_short": "MINIMAR",
    "name_full": "Minimum Information for Medical AI Reporting",
    "year": "2020",
    "link_to_study": "https://academic.oup.com/jamia/article/27/12/2011/5864179",
    "link_to_checklist": "https://academic.oup.com/view-large/218969405",
    "focus_area": "Generic",
    "d": "M105.108,175.298c0,2.971-2.43,5.4-5.398,5.4H26.507c-2.969,0-5.399-2.43-5.399-5.4v-8.256c0-2.971,2.433-5.4,5.399-5.4h73.201c2.971,0,5.398,2.43,5.398,5.4L105.108,175.298L105.108,175.298z"
  }
]

export default Mapping;

