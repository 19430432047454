const Mapping = [
  {
    "id": "Aiforia",
    "year": "2013",
    "website": "https://www.aiforia.com/",
    "d": "M1207.109,1280.771c0,7.033-5.699,12.734-12.729,12.734h-149.271c-7.027,0-12.725-5.701-12.725-12.734v-149.382c0-7.026,5.695-12.724,12.725-12.724h149.271c7.027,0,12.729,5.695,12.729,12.724V1280.771z",
    "text": '<strong>70% Laboratory Operations</strong><br><ul><li>They have a web-based viewer for clinical pathology workflow promising to speed up case review and allow for remote viewing and collaboration<sup><a href="https://www.aiforia.com/aiforia-clinical" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Previously in the lab operations business, they were known as "Webmicroscope" with the tagline "view, share, and learn from digitized microscope specimen slides worldwide"<sup><a href="http://demo.webmicroscope.net/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>30% Research & Development</strong><br><ul><li>Most of the applications of their "Create" platform have been focused on R&D (preclinical image analysis) with partners including MIT, Sanofi, and Charles River Laboratories. Tasks performed include quantifying cells & cellular deposits in brain tissue, as well as calculating protein expression<sup><a href="https://www.aiforia.com/aiforia-create" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Techcyte",
    "year": "2013",
    "website": "https://techcyte.com/",
    "d": "M1339.146,834.077c0,7.033-5.702,12.734-12.729,12.734h-149.251c-7.027,0-12.724-5.701-12.724-12.734V684.695c0-7.026,5.696-12.723,12.724-12.723h149.251c7.027,0,12.729,5.694,12.729,12.723V834.077z",
    "text": '<strong>100% Laboratory Operations</strong><br><ul><li>Focus on making labs "more efficient, accurate, and profitable"<sup><a href="https://techcyte.com/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Partnerships with multiple pathology labs<sup><a href="https://techcyte.com/partners/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Proscia",
    "year": "2014",
    "website": "https://proscia.com/",
    "d": "M1141.548,1038.325c0,7.025-5.695,12.721-12.724,12.721H979.548c-7.026,0-12.721-5.693-12.721-12.721V889.046c0-7.025,5.692-12.722,12.721-12.722h149.276c7.026,0,12.724,5.694,12.724,12.722V1038.325z",
    "text": '<strong>70% Laboratory Operations</strong><br><ul><li>The tagline "Accelerate your pathology lab" hints at a major focus on developing solutions for pathology labs with and a focus on operational efficiency, quality assurance, and productivity tools<sup><a href="https://proscia.com/clinical/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>. </li><li>They provide multiple integrations with WSI scanners and lab information systems, as well as cloud-based offerings for data storage<sup><a href="https://proscia.com/concentriq-platform/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>They partner with Huron digital pathology, an imaging hardware incumbent in the space<sup><a href="https://proscia.com/product-releases/proscia-and-huron-digital-pathology-partner-to-provide-seamlessly-integrated-digital-pathology-scanner-software-solution/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>30% Research & Development</strong><br><ul><li>They partner with Visiopharm who provides quantitative digital pathology solutions for pharma and CROs<sup><a href="https://proscia.com/product-releases/visiopharm-and-proscia-partner-to-deliver-ai-enabled-solutions-that-advance-precision-medicine/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Path AI",
    "year": "2016",
    "website": "https://www.pathai.com/",
    "d": "M511.873,2123.791c0,7.025-5.701,12.721-12.729,12.721h-149.33c-7.026,0-12.721-5.693-12.721-12.721v-149.279c0-7.025,5.692-12.723,12.721-12.723h149.331c7.025,0,12.729,5.695,12.729,12.723L511.873,2123.791L511.873,2123.791z",
    "text": '<strong>100% Research & Development</strong><br><ul><li>Path AI is working or has worked with 3 pharmaceutical companies including Bristol Myers Squibb<sup><a href="https://www.pathai.com/news/pathai-bms-sitc-2019" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>, Gilead sciences<sup><a href="https://www.pathai.com/news/pathai-gilead-nash-aasld-liver-2019" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>, and Novartis<sup><a href="https://www.novartis.com/stories/discovery/artificial-intelligence-decodes-cancer-pathology-images" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>. </li><li>The branding is clear: "artificial intelligence (AI)-powered technology for use in pathology research"<sup><a href="https://www.pathai.com/news/pathai-bms-sitc-2019" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>. Their CEO is quoted saying "Our biggest focus today is a research platform; we use it to examine new therapeutics for serious diseases"<sup><a href="https://techcrunch.com/2019/04/17/pathai-raises-60-million-for-its-computer-vision-based-pathogen-detection-technology/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Their only collaboration that might hint at some clinical work is with Phillips, although that was from 2017 with no updates<sup><a href="https://www.fiercebiotech.com/deals/philips-pathai-team-up-breast-cancer-dx" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Ibex",
    "year": "2016",
    "website": "https://ibex-ai.com/",
    "d": "M1983.283,1617.747c0,7.033-5.701,12.733-12.729,12.733h-149.271c-7.026,0-12.721-5.7-12.721-12.733v-149.382c0-7.026,5.692-12.724,12.721-12.724h149.271c7.027,0,12.729,5.695,12.729,12.724V1617.747z",
    "text": '<strong>70% Clinical Decision Support</strong><br><ul><li>Flagship product\'s main offering is diagnostic AI for cancer detection and grading<sup><a href="https://ibex-ai.com/solutions/the-galen-platform/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Partnership with Israel\'s second largest HMO, Maccabi Healthcare Services, where they have been working on full scale pathology digitization<sup><a href="https://ibex-ai.com/maccabi-healthcare/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>30% Laboratory Operations</strong><br><ul><li>Same product also promises to "increase productivity and efficiency" and "supercharge pathology lab operations" with AI reads prior to and during pathologist reads - the latter being for flagging misdiagnosed cases, identifying discrepancies, and general quality control.</li></ul>'
  },
  {
    "id": "Deep Lens",
    "year": "2017",
    "website": "https://www.deeplens.ai/",
    "d": "M879.794,1906.857c0,7.025-5.699,12.721-12.727,12.721H717.794c-7.025,0-12.723-5.693-12.723-12.721v-149.279c0-7.025,5.695-12.722,12.723-12.722h149.273c7.025,0,12.727,5.694,12.727,12.722V1906.857z",
    "text": '<strong>70% Research & Development</strong><br><ul><li>Their platform is used to collect data points (from lab information systems and electronic health records) and match patients to clinical trials, with the tagline "Matching the right patient with the right trial at the right time".</li><li>They partner with Worldwide clinical trials, a CRO.</li></ul><strong>15% Laboratory Operations</strong><br><ul><li>They provide a "free-of-charge" cloud-based digital pathology workflow management platform to increase lab efficiency<sup><a href="https://blog.deeplens.ai/blog/ai-expert-perspective-interview" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>15% Clinical Decision Support</strong><br><ul><li>The platform also allows pathologists to register consultations and reviews, especially during tumor boards<sup><a href="https://www.deeplens.ai/viper" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Deciphex",
    "year": "2017",
    "website": "https://deciphex.com/",
    "d": "M935.665,1396.219c0,7.023-5.702,12.721-12.729,12.721H773.684c-7.025,0-12.723-5.693-12.723-12.721v-149.311c0-7.025,5.693-12.723,12.723-12.723h149.251c7.027,0,12.729,5.693,12.729,12.723L935.665,1396.219L935.665,1396.219z",
    "text": '<strong>50% Laboratory Operations</strong><br><ul><li>Their flagship product is an image viewer with a focus on "ergonomic workflows" and "productivity optimization".</li></ul><strong>50% Research & Development</strong><br><ul><li>Tagline: "preclinical digital pathology software-as-a-service", and product designed for "pharma and CROs"<sup><a href="https://deciphex.com/deciphex-release-patholytix-preclinical.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>They collaborate with Janssen Pharmaceuticals and PDS, a lifesciences saas provider for provisioning LIMS (Laboratory information management systems) for preclinical studies<sup><a href="https://www.deciphex.com/deciphex-awarded-2-million-from-eu-fast-track-to-innovation-programme.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>. They also partner with Charles River Laboratories (an imaging contract research organization - or CRO)<sup><a href="https://deciphex.com/charles-river-laboratories-and-deciphex-form-a-partnership-to-enhance-drug-discovery-development-with-digital-pathology.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>, and collaborate with Cernostics (molecular cancer diagnostics) to develop AI-based prognostic biomarkers in pathology slides<sup><a href="https://www.deciphex.com/cernostics-and-deciphex-announce-strategic-collaboration-to-develop-ai-based-histology-diagnostics-and-prognostics-for-barretts-esophagus.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Qritive",
    "year": "2017",
    "website": "https://www.qritive.com/",
    "d": "M1805.315,1321.683c0,7.026-5.699,12.723-12.729,12.723h-149.271c-7.027,0-12.725-5.694-12.725-12.723v-149.279c0-7.025,5.695-12.722,12.725-12.722h149.271c7.027,0,12.729,5.694,12.729,12.722V1321.683z",
    "text": '<strong>50% Clinical Decision Support</strong><br><ul><li>"AI plugins" for their flagship product provide clinical decision support through disease grading, metastasis detection, as well diagnosis<sup><a href="https://www.qritive.com/products" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>50% Laboratory Operations</strong><br><ul><li>Their flagship product provides workflow management tools, cloud storage, as well as telepathology capabilities<sup><a href="https://www.qritive.com/products" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Nucleai",
    "year": "2017",
    "website": "https://www.nucleaimd.com/",
    "d": "M647.77,1884.01c0,7.026-5.699,12.724-12.729,12.724H485.77c-7.027,0-12.725-5.695-12.725-12.724v-149.278c0-7.026,5.695-12.723,12.725-12.723h149.271c7.027,0,12.729,5.693,12.729,12.723V1884.01z",
    "text": '<strong>100% Research & Development</strong><br><ul><li>Focus on developing a digital-biomarker platform to streamline how pathology images are structured, analyzed, and leveraged in the development of novel therapies<sup><a href="https://www.nucleaimd.com/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Access to real-world data through strategic partnerships with medical centers in Israel and US.</li><li>Collaborations with several pharmaceutical companies, including Debiopharm, who acts as lead investor and partner in biomarker development for novel therapeutics<sup><a href="https://www.debiopharm.com/digital-health-investment/press-releases/nucleai-secured-6-5m-series-a-initial-closing-led-by-debiopharm/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Paige",
    "year": "2017",
    "website": "https://paige.ai/",
    "d": "M2008.677,1962.294c0,7.025-5.696,12.721-12.724,12.721h-149.277c-7.023,0-12.723-5.693-12.723-12.721v-149.28c0-7.025,5.697-12.722,12.723-12.722h149.279c7.025,0,12.724,5.694,12.724,12.722v149.28H2008.677z",
    "text": '<strong>70% Clinical Decision Support</strong><br><ul><li>"Paige Prostate" is the first CE marked deep learning product in pathology<sup><a href="https://www.prnewswire.com/news-releases/paige-announces-first-ever-ce-mark-for-deep-learning-in-pathology-300956164.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup> and has been granted the FDA breakthrough device designation<sup><a href="https://www.healio.com/news/hematology-oncology/20190307/fda-grants-breakthrough-device-designation-to-artificial-intelligence-technology-for-cancer-diagnosi" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup> - essentially allowing for a more speedy regulatory approval whilst maintaining standards for approvals<sup><a href="https://www.fda.gov/medical-devices/how-study-and-market-your-device/breakthrough-devices-program" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>15% Laboratory Operations</strong><br><ul><li>Paige has two imaging viewers, "Paige Insight" - CE marked<sup><a href="https://www.prnewswire.com/news-releases/paige-announces-first-ever-ce-mark-for-deep-learning-in-pathology-300956164.html#:~:text=12%2C%202019%20%2FPRNewswire%2F%20%2D%2D,viewer%2C%20both%20for%20primary%20diagnosis." target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup> and "Paige FullFocus" - FDA cleared<sup><a href="https://www.businesswire.com/news/home/20200721005369/en/Paige-Receives-FDA-Clearance-FullFocus%E2%84%A2-Viewer-Digital" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup> to handle workflow management and improve lab efficiency.</li></ul><strong>15% Research & Development</strong><br><ul><li>They are partnered with Invicro, a CRO<sup><a href="https://invicro.com/invicro-and-paige-partner-to-offer-comprehensive-tissue-biomarker-profiling-services/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Primaa",
    "year": "2018",
    "website": "https://www.primaalab.com/",
    "d": "M2259.636,2118.864c0,7.034-5.696,12.734-12.724,12.734h-149.273c-7.025,0-12.725-5.7-12.725-12.734v-149.38c0-7.027,5.697-12.724,12.725-12.724h149.273c7.025,0,12.724,5.694,12.724,12.724V2118.864z",
    "text": '<strong>100% Clinical Decision Support</strong><br><ul><li>Early product aims to "automate sample analysis with the goal of offering an initial diagnosis that is instantaneous, certain and precise"<sup><a href="https://www.eu-startups.com/2019/10/paris-based-health-tech-startup-primaa-raises-e2-million-for-its-cancer-diagnosis-software/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "DeePathology",
    "year": "2018",
    "website": "https://deepathology.ai/",
    "d": "M1606.885,1517.134c0,7.025-5.695,12.722-12.723,12.722h-149.275c-7.024,0-12.724-5.694-12.724-12.722v-149.28c0-7.025,5.697-12.721,12.724-12.721h149.275c7.025,0,12.723,5.693,12.723,12.721V1517.134z",
    "text": '<strong>50% Clinical Decision Support</strong><br><ul><li>They have an offering for detecting and diagnosing H. Pylori (bacteria) in hematoxylin and eosin (H&E) and Giemsa-stained gastric biopsies<sup><a href="https://www.globenewswire.com/news-release/2019/12/06/1957189/0/en/Inspirata-and-DeePathology-Announce-New-Technical-Partnership-and-Solution-Integration.html" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>50% Laboratory Operations</strong><br><ul><li>Their "studio" offering enables users to develop AI-based solutions for cell and object detection as well as classification and region segmentation - all of which promise to increase efficiency.</li></ul>'
  },
  {
    "id": "Medmain",
    "year": "2018",
    "website": "https://en.medmain.com/",
    "d": "M1588.213,1267.974c0,7.027-5.696,12.724-12.723,12.724h-149.277c-7.023,0-12.723-5.694-12.723-12.724v-149.277c0-7.027,5.697-12.723,12.723-12.723h149.277c7.024,0,12.723,5.693,12.723,12.723V1267.974z",
    "text": '<strong>50% Clinical Decision Support</strong><br><ul><li>Their scientific publications explore clinical applications with a focus on histopathological classification of gastric and colonic epithelial tumours<sup><a href="https://www.nature.com/articles/s41598-020-58467-9" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup> as well as lung carcinoma classification, which are both routine clinical applications<sup><a href="https://pubmed.ncbi.nlm.nih.gov/32518413/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Their flagship product offers "AI-Driven Pathological Image Diagnosis"<sup><a href="https://pidport.medmain.com/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>50% Laboratory Operations</strong><br><ul><li>The same product also boosts telepathology and cloud storage capabilities, in addition to being an image viewer.</li><li>They operate an "imaging center" where they convert glass slides into digital images and store them into their cloud - essentially slide digitization-as-a-service<sup><a href="https://en.medmain.com/pressrelease/1550/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "MindPeak",
    "year": "2018",
    "website": "https://www.mindpeak.ai/",
    "d": "M1579.582,917.896c0,7.025-5.697,12.728-12.723,12.728h-149.276c-7.025,0-12.723-5.699-12.723-12.728V768.621c0-7.024,5.697-12.722,12.723-12.722h149.276c7.023,0,12.723,5.695,12.723,12.722V917.896z",
    "text": '<strong>70% Laboratory Operations</strong><br><ul><li>Their flagship product is focused on performing otherwise tedious and time-consuming tasks such as cell quantification<sup><a href="https://www.mindpeak.ai/breastihc/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>They focus on "optimizing workflows in cancer diagnostics"<sup><a href="https://www.mindpeak.ai/careers/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Multiple partnerships with pathology labs<sup><a href="https://www.mindpeak.ai/about-us/partners/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>30% Clinical Decision Support</strong><br><ul><li>Tagline: "Our mission is to increase cancer diagnostic accuracy".</li><li>Flagship product\'s initial focus on a specific cancer site: breast<sup><a href="https://www.mindpeak.ai/breastihc/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Multiple partnerships with providers<sup><a href="https://www.mindpeak.ai/about-us/partners/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Aignostics",
    "year": "2018",
    "website": "http://www.aignostics.com/",
    "d": "M1399.638,2122.578c0,7.027-5.696,12.726-12.724,12.726h-149.276c-7.024,0-12.724-5.696-12.724-12.726v-149.275c0-7.026,5.697-12.723,12.724-12.723h149.276c7.025,0,12.724,5.693,12.724,12.723V2122.578z",
    "text": '<strong>50% Clinical Decision Support</strong><br><ul><li>Their solution is "geared to meeting the needs of routine diagnostics and clinical medicine"<sup><a href="https://www.bihealth.org/en/notices/precise-diagnostics-with-ai-bih-digital-health-accelerator-guides-aignostics-gmbh-through-its-spin-off/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul><strong>50% Research & Development</strong><br><ul><li>Focus on imaging biomarkers for drug development<sup><a href="https://www.aignostics.com/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li><li>Focus on developing companion diagnostics<sup><a href="https://www.bihealth.org/en/notices/precise-diagnostics-with-ai-bih-digital-health-accelerator-guides-aignostics-gmbh-through-its-spin-off/" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  },
  {
    "id": "Crosscope",
    "year": "2019",
    "website": "https://www.crosscope.com/",
    "d": "M1391.817,600.724c0,7.025-5.7,12.721-12.729,12.721h-149.273c-7.026,0-12.723-5.693-12.723-12.721V451.445c0-7.025,5.694-12.722,12.723-12.722h149.273c7.026,0,12.729,5.694,12.729,12.722V600.724z",
    "text": '<strong>100% Laboratory Operations</strong><br><ul><li>Their flagship product is an image viewer with focus on workflow management, data storage, integration with slide scanners, collaboration, and remote academic institution.</li><li>They have a partnership with Microvisioneer, a pathology slide digitization company<sup><a href="https://www.microvisioneer.com/post/microvisioneer-crosscope" target="_blank" rel="noopener noreferrer">&#8599;&#xfe0e;</a></sup>.</li></ul>'
  }
]



export default Mapping;

