import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import Layout from "../components/layout"
import Footer from "../components/footer"
//
import SEO from "../components/seo"
import SubscribtionForm from "../components/subscribtionForm"

// covers
import PathologyCover from "../covers/PathologyCover"
import GuidelinesCover from "../covers/GuidelinesCover"

const covers = {
  pathology: PathologyCover,
  guidelines: GuidelinesCover,
}

const Post = tw.main`
max-w-2xl mx-auto p-2 mt-4
`

const Title = tw.h2`
w-full text-4xl font-sans font-black text-darkgrey leading-tight
`

const Date = tw.p`
w-full mt-2 mb-8 text-sm font-serif text-darkgrey
`

const Section =
  "prose text-darkgrey font-serif font-normal text-lg md:text-base"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title
  const SubscribtionFormDiv = (
    <div className="mt-6 mb-6">
      <SubscribtionForm />
    </div>
  )
  const Header = (
    <header>
      <Title>{post.frontmatter.title}</Title>
      <Date>
        {post.frontmatter.date}
        <span> &#8212; </span>
        {post.fields.readingTime.text}
      </Date>
    </header>
  )
  // function to select which cover to get
  const GetCover = () => {
    if (post.frontmatter.cover === "true") {
      const CoverComponent = covers[post.frontmatter.coverName]
      return <CoverComponent>{Header}</CoverComponent>
    }

    return ""
  }
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      {GetCover()}
      <Post>
        <article>
          {post.frontmatter.cover === "true" ? "" : Header}
          <section
            className={Section}
            dangerouslySetInnerHTML={{ __html: post.frontmatter.abstract }}
          />
          {SubscribtionFormDiv}
          <section
            className={Section}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
          <Footer/>
        </article>
      </Post>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover
        coverName
        abstract
      }
    }
  }
`
