import React from "react"
import tw, { styled } from "twin.macro"
import Background from "../../content/blog/artificial-intelligence-ai-guidelines-checklists-medicine-healthcare/cover/background.inline.svg"
import Text from "../../content/blog/artificial-intelligence-ai-guidelines-checklists-medicine-healthcare/cover/text.inline.svg"
import Mapping from "../../content/blog/artificial-intelligence-ai-guidelines-checklists-medicine-healthcare/cover/Mapping.js"
import TwConfig from "../../tailwind.config.js"

// company div
const GuidelineDiv = styled.div`
  ${tw`
  w-full pt-3 pb-2
  `}
  height: 260px;
`

// values in key/value pairs
const Values = styled.div`
  ${tw`
  text-xs font-thin inline
  `}
`
// values in key/value pairs
const ValuesLink = styled.div`
  ${tw`
  underline text-xs font-thin inline
  `}
`

// dummy to ensure parent has height even though contents are absolute
const Dummy = styled.div`
  ${tw`
   bg-mediumgrey z-0 
  `}
  padding-top: 70%;
`

// dummy empty company
const DummyGuideline = {
  name_short: "",
  name_full: "",
  year: "",
  link_to_study: "",
  focus_area: "",
  d: "",
}

class GuidelinesCover extends React.Component {
  // two-pass rendering:
  // https://github.com/gatsbyjs/gatsby/issues/17914
  constructor() {
    super()
    this.state = {
      current_guideline: DummyGuideline,
    }
  }

  componentDidMount() {
    this.setState({
      current_guideline: Mapping[Math.floor(Math.random() * Mapping.length)],
    })
  }

  _onGuidelineClick(name_short) {
    this.setState({
      current_guideline: Mapping.filter((obj) => {
        return obj.name_short === name_short
      })[0],
    })
    console.log(
      "onGuidelineClick: ",
      this.state.current_guideline.name_short,
      Mapping.filter((obj) => {
        return obj.name_short === name_short
      })[0].name_short
    )
  }

  _changeFill(name_short) {
    if (name_short === this.state.current_guideline.name_short) {
      return TwConfig.theme.extend.colors.nepal
    }
    return "#fff"
  }

  render() {
    const current = this.state.current_guideline
    const extension = (
      <div>
        Extension of: <Values>{current.extension_of}</Values>{" "}
      </div>
    )
    return (
      // band
      <div className="w-full bg-mediumgrey mb-10">
        {/* container */}
        <div className="max-w-6xl mt-4 mb-4 mx-auto bg-mediumgrey flex flex-wrap content-start justify-end p-2">
          {/* column 1 - left column has title */}
          <div className="w-full lg:w-2/5 p-2 pt-8 lg:pr-24">
            {/* title and date */}
            <div className="w-full text-darkgrey">{this.props.children}</div>
            {/* instructions */}
            <div className="w-full font-light italic text-sm text-darkgrey">
              Click on guideline to view details.
            </div>
            {/* horizontal sperarator line */}
            <hr className="border-0 bg-blue-900 h-px" />
            <GuidelineDiv>
              {/* guideline name_short */}
              <div className="w-full prose text-darkgrey font-serif font-semibold text-2xl">
                {current.name_short}
                <span> </span>
                {/* guideline year */}
                <span className="text-sm">({current.year})</span>
              </div>

              {/* guideline name_long */}
              <div className="w-full prose text-darkgrey font-serif font-normal text-base leading-snug">
                {current.name_full}
              </div>

              <div className="w-full text-darkgrey font-serif font-normal text-sm py-2">
                Focus area: <Values>{current.focus_area}</Values>
                <br />
                Statement paper:{" "}
                <ValuesLink>
                  <a
                    href={current.link_to_study}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    link
                  </a>
                </ValuesLink>
                <br />
                Checklist:{" "}
                {current.link_to_checklist ? (
                  <ValuesLink>
                    <a
                      href={current.link_to_checklist}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      link
                    </a>
                  </ValuesLink>
                ) : (
                  <Values>Under development April 2021</Values>
                )}
                <br /> {current.extension_of ? extension : null}
              </div>
            </GuidelineDiv>
          </div>
          {/* column 2 with dummy and figure */}
          <div className="w-full lg:w-3/5 bg-mediumgrey relative lg:pt-6">
            <Background className="absolute w-full z-10  h-auto" />
            <Text className="absolute w-full z-30 pointer-events-none h-auto" />
            <svg
              className="absolute w-full z-20 h-auto"
              x="0px"
              y="0px"
              width="597px"
              height="379.642px"
              viewBox="0 0 597 379.642"
            >
              {Mapping.map((company, index) => {
                return (
                  <path
                    className="cursor-pointer"
                    key={index}
                    stroke="#424242"
                    strokeWidth="1"
                    fill={this._changeFill(company.name_short)}
                    d={company.d}
                    onClick={() => this._onGuidelineClick(company.name_short)}
                  />
                )
              })}
            </svg>
            <Dummy />
          </div>
        </div>
      </div>
    )
  }
}

export default GuidelinesCover
