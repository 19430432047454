/* eslint-disable react/no-unescaped-entities */
import React from "react"
import tw from "twin.macro"
import SubscribtionForm from "./subscribtionForm"

const FooterDiv = tw.footer`
mb-12 text-darkgrey
`
const SubscribtionFormDiv = (
  <div className="mt-6 mb-6">
    <SubscribtionForm />
  </div>
)

const A = tw.a`
underline text-darkgrey
`

const Footer = () => {
  return (
    <FooterDiv>
      {SubscribtionFormDiv}
      Frustrations, criticism, fact-checks, comments? Let's{" "}
      <A href="https://twitter.com/ahmedhosny" target="_blank" rel="noreferrer">
        talk
      </A>
      . <br />
      If you like the content, you can read more{" "}
      <A
        href="https://ahmedhosny.com/publications"
        target="_blank"
        rel="noreferrer"
      >
        here
      </A>
      .
    </FooterDiv>
  )
}

export default Footer
