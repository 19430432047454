import React from "react"
import tw, { styled } from "twin.macro"
import Background from "../../content/blog/artificial-intelligence-ai-startups-pathology-venture-meta-review-analysis/cover/background-2.inline.svg"
import Logos from "../../content/blog/artificial-intelligence-ai-startups-pathology-venture-meta-review-analysis/cover/logos.inline.svg"
import Mapping from "../../content/blog/artificial-intelligence-ai-startups-pathology-venture-meta-review-analysis/cover/Mapping.js"
import TwConfig from "../../tailwind.config.js"

// company div
const CompanyDiv = styled.div`
  ${tw`
  w-full pt-3 pb-5
  `}
  height: 470px;
`

// dummy to ensure parent has height even though contents are absolute
const Dummy = styled.div`
  ${tw`
   bg-mediumgrey z-0 
  `}
  padding-top: 100%;
`

// dummy empty company
const DummyCompany = {
  id: "",
  year: "",
  website: "",
  d: "",
  text: "",
}

class PathologyCover extends React.Component {
  // two-pass rendering:
  // https://github.com/gatsbyjs/gatsby/issues/17914
  constructor() {
    super()
    this.state = {
      current_company: DummyCompany,
    }
  }

  componentDidMount() {
    this.setState({
      current_company: Mapping[Math.floor(Math.random() * Mapping.length)], // Mapping[3]
    })
  }

  _onCompanyClick(id) {
    this.setState({
      current_company: Mapping.filter((obj) => {
        return obj.id === id
      })[0],
    })
    console.log(
      "oncompanyclick: ",
      this.state.current_company.id,
      Mapping.filter((obj) => {
        return obj.id === id
      })[0].id
    )
  }

  _changeFill(id) {
    if (id === this.state.current_company.id) {
      return TwConfig.theme.extend.colors.darkgrey
    }
    return "transparent"
  }

  render() {
    return (
      // band
      <div className="w-full bg-mediumgrey mb-10">
        {/* container */}
        <div className="max-w-6xl mt-4 mb-4 mx-auto bg-mediumgrey flex flex-wrap content-start justify-end p-2">
          {/* column 1 - left column has title */}
          <div className="w-full lg:w-2/5 p-2 pt-8 lg:pr-8">
            {/* title and date */}
            <div className="w-full ">{this.props.children}</div>
            {/* instructions */}
            <div className="w-full font-light italic text-sm text-gray-900">
              Click on startup logo to view analysis notes.
            </div>
            {/* horizontal sperarator line */}
            <hr className="border-0 bg-blue-900 h-px" />
            <CompanyDiv>
              {/* company name */}
              <div className="w-full prose text-gray-900 font-serif font-normal text-xl py-2">
                {this.state.current_company.id}
                <sup>
                  <a
                    href={this.state.current_company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &#8599;&#xfe0e;
                  </a>
                </sup>
                <span> </span>
                {/* company year */}
                <span className="text-sm">
                  ({this.state.current_company.year})
                </span>
              </div>
              <section
                className="max-w-full prose text-gray-900 font-serif font-normal text-xs"
                dangerouslySetInnerHTML={{
                  __html: this.state.current_company.text,
                }}
              />
            </CompanyDiv>
          </div>
          {/* column 2 with dummy and figure */}
          <div className="w-full lg:w-3/5 bg-mediumgrey relative">
            <Background className="absolute w-full h-auto z-10" />
            <Logos className="absolute w-full h-auto z-30 pointer-events-none" />
            <svg
              className="absolute w-full h-auto z-20"
              x="0px"
              y="0px"
              width="2601.539px"
              height="2601.541px"
              viewBox="0 0 2601.539 2601.541"
            >
              {Mapping.map((company, index) => {
                return (
                  <path
                    className="cursor-pointer"
                    key={index}
                    stroke={TwConfig.theme.extend.colors.darkgrey}
                    strokeWidth="2"
                    fill={this._changeFill(company.id)}
                    d={company.d}
                    onClick={() => this._onCompanyClick(company.id)}
                  />
                )
              })}
            </svg>
            <Dummy />
          </div>
        </div>
      </div>
    )
  }
}

export default PathologyCover
